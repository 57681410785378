import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="lg:text-lg container bg-purple-light mt-20vh p-10">
      <h1 className="block p-5 text-2xl md:text-3xl md:p-10 font-semibold">
        NOT FOUND
      </h1>
      <p className="p-5 text-xl md:text-2xl font-medium">
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
